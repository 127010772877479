<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
			<div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value">
					</el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="Search()">搜索</el-button>
			</div>
			<div>
				<span class="demonstration">访问时间：</span>
				<el-date-picker size="small" style="width: 230px" @change="Search()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="shared_time" label="分享时间">
				<template slot-scope="scope">{{ scope.row.shared_time*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="member_head_img" label="头像">
				<template slot-scope="scope">
					<img :src="scope.row.member_head_img || ''" style="width: 80px" alt="" />
				</template>
			</el-table-column>
			<el-table-column prop="video_id" label="视频id"></el-table-column>
			<el-table-column prop="enter_member_id" label="访问用户id"></el-table-column>
			<el-table-column prop="member_name" label="访问用户昵称"></el-table-column>
			<el-table-column prop="member_phone" label="访问用户手机号"></el-table-column>
			<el-table-column prop="member_id" label="推荐人id"></el-table-column>
			<el-table-column prop="sharer_name" label="推荐人昵称"></el-table-column>
			<el-table-column prop="sharer_phone" label="推荐人手机号"></el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>
<script>
	import { shareList } from '@/api/base.js';
	export default {
		name: "ShareList",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				//搜索依据
				searchGist: [{
					value: '微信昵称',
					label: '微信昵称'
				}, {
					value: '手机号',
					label: '手机号'
				}],
				// 列表
				search: {
					search_gist: '微信昵称',
					desp: '',
					time: '',
				},
				// 列表
				total: 0,
				limit: 10,
				currentPage: 1,
				list: [],
				dialogVisible: false,
				id: 0
			}
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 查看详情
			showDetail(id) {
				this.$refs.detail.getUserDetail(id)
				this.id = id
				this.dialogVisible = true
			},
			// 获取列表
			getList() {
				shareList({
					limit: this.limit,
					page: this.currentPage,
					wx_nickname: this.search.search_gist == '微信昵称' ? this.search.desp : '', //昵称搜索
					member_phone: this.search.search_gist == '手机号' ? this.search.desp : '', //电话搜索
					create_start_time: this.$common.setSearchTime(this.search.time)[0],
					create_end_time: this.$common.setSearchTime(this.search.time)[1]
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					console.log(err, 222)
				})
			},
			// 搜索
			Search(){
				this.loading = true
				this.currentPage = 1
				this.getList()
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true
				this.currentPage = val;
				this.getList();
			},
		}
	};
</script>
